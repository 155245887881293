/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { default as anime } from 'packs/backend/anime.es.js';

window.closeNotice = function(delaytime) {
  if (delaytime == null) { delaytime = 4500; }
  if (!document.getElementById('notice')) { return; }
  const noticeTimeline = anime.timeline({});

  noticeTimeline
    .add({
      targets: '#notice',
      opacity: [ {value: 0}, {value: 0.8}],
      translateX: [ { value: 400 }, { value: 0 } ],
      duration: 1000}).add({
      targets: '#notice',
      opacity: 0,
//      easing: 'easeInOutQuad'
      translateX: 20,
      delay: delaytime,
      complete() {
        if (document.getElementById('notice')) { document.getElementById('notice').remove(); }
      }
  });
};

window.notify = function(notice, delaytime) {
  if (delaytime == null) { delaytime = 2500; }
  const old_notice = document.getElementById('notice');
//  anime.remove('#notice')
//  old_notice.remove() if old_notice
  if (old_notice) {
    old_notice.innerHTML = notice;
  } else {
    const t = document.createTextNode(notice);
    const header = document.getElementsByTagName("header")[0];
    const the_notice = document.createElement("P");
    the_notice.id = 'notice';
    the_notice.appendChild(t);
    header.appendChild(the_notice);
    closeNotice(delaytime);
  }
};

document.addEventListener("turbolinks:load", () => closeNotice());
