window.openModal = function(hashistory) {
  var modal;
  modal = document.getElementById('modal');
  modal.style.display = 'block';
  if (hashistory) {
    modal.setAttribute('data-has-history', 'true');
  }
};

window.closeModal = function() {
  var modal;
  modal = document.getElementById('modal');
  if (!modal.getAttribute('data-history-path')) {
    if (modal.getAttribute('data-has-history') === 'true') {
      history.back();
    } else {
      return modal.style.display = 'none';
    }
  } else {
    Turbolinks.visit(modal.getAttribute('data-history-path'));
  }
};

document.addEventListener("turbolinks:load", function() {
  var modal, modalspan, modalyield;
  modal = document.getElementById('modal');
  modalspan = document.getElementById('modalclose');
  modalspan.onclick = function() {
    closeModal();
  };
  modal.onclick = function(event) {
    if (event.target === modal) {
      closeModal();
    }
  };
  modalyield = document.getElementById('modal-yield');
  if (modalyield.textContent.trim('') !== '') {
    openModal();
  }
});
